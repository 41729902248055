<template>
  <div>
    <label :for="inputId" @dragenter="dragEnter" @dragleave="dragLeave" @dragover="dragOver" @drop="handleDrop">
      <div v-if="!$slots.default">
        <div class="upload" :style="{width, height}">
          <div class="icon-add"></div>
          <div class="tips">点击或拖拽文件到此处上传</div>
        </div>
      </div>
      <slot></slot>
    </label>
    <input ref="inputRef" :id="inputId" type="file" style="display: none;" :multiple="multiple" @change="chooseFile">
    <div class="file-list" v-if="showFileList">
      <div class="file-item" v-for="(item, index) in fileList" :key="index">
        <div class="left">
          <img class="icon-file" src="@/assets/images/fujian.png">
          <span>{{item.name}}</span>
        </div>
        <img class="btn-delete" src="@/assets/images/icon-delete.png" @click="handleRemove(index, item)">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inputId: ''
    }
  },
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100px'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    showFileList: {
      type: Boolean,
      default: false
    },
    fileList: {
      type: Array,
      default: () => []
    }
  },
  created() {
    this.inputId = 'input_'+(parseInt(Math.random()*10000) + Date.now()).toString()
  },
  methods: {
    chooseFile(e) {
      const fileList = e.target.files && e.target.files.length > 0 ? [...e.target.files] : []
      console.log('选择文件',fileList)
      this.handleFileList(fileList)
      this.$refs.inputRef.value = ''
    },
    handleRemove(index, file) {
      this.$emit('remove', {index, file})
    },
    handleDrop(e) {
      e.preventDefault();
      let fileList = e.dataTransfer.files.length > 0 ? [...e.dataTransfer.files] : []
      console.log('---拖拽----', fileList)
      this.handleFileList(fileList)
    },
    handleFileList(fileList) {
      fileList = fileList.filter(item => item.type && item.size)
      if(fileList.length <= 0) return
      if(this.multiple) {
        this.$emit('success', fileList)
      } else {
        this.$emit('success', fileList[0])
      }
    },
    dragEnter(e) {
      e.preventDefault();
    },
    dragLeave(e) {
      e.preventDefault();
    },
    dragOver(e) {
      e.preventDefault();
    },
  }
}
</script>

<style lang="scss" scoped>
.upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed #E5E6EB;
  border-radius: 2px;
  cursor: pointer;
  .icon-add {
    width: 20px;
    height: 20px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAASNJREFUeF7tmkESwjAIRckt1QOwV8+h7jmAekudjF2k7ho6wzC8rktJPvDzCW1S/GnF9y8AQAYUR4ASKJ4AkCAlQAkUR4ASKJ4AoadAU9VbD4CZXUXkExGMsBJQ1ZeIHJZNv83sWA2AVcTNLCQYIU57pFUVAMaUJwMoATgghI9CnEKCnAIcg+gAhBBKEClML5C5F+iXGc+hn49o5Uef/T7hNHOpMqUEVfUuIufoXf/5f5jZZeuaAGArYsv7tUtgErSVGUIIIYQQQghlFkJuHoQEIUFIEBKEBAcEGI0xGmM0NtWaewVJiFNGY7/RWO1fZESk9k9S3trdyz6MA/bagPc7AOBFMLs9GZA9gt71kwFeBLPbkwHZI+hdPxngRTC7ffkM+AJ8FaJBp0XotgAAAABJRU5ErkJggg==);
    background-size: contain;
    background-repeat: no-repeat;
  }
  .tips {
    color: #1D2129;
  }
}
.file-list {
  .file-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    .left {
      display: flex;
      align-items: center;
    }
    .icon-file {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
    .btn-delete {
      width: 16px;
      height: 16px;
      margin-right: 4px;
      cursor: pointer;
    }
  }
}
</style>